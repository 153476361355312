import React from 'react';
import styled from 'styled-components';

const CarStyles = styled.div`
  display: flex;
  background-color: ${props => props.theme.green};
  flex-direction: column;

  p {
    font-size: 1.45rem;
    margin: 3rem 1.25rem;
    line-height: 2.5rem;

    @media ${props => props.theme.size.md} {
      font-size: 1.65rem;
      line-height: 3rem;
      margin: 3rem 8rem;
    }

    @media ${props => props.theme.size.lg} {
      margin: 3rem 12rem;
    }

    @media ${props => props.theme.size.xl} {
      margin: 3rem 18rem;
    }
  }

  p:last-child {
    padding-top: 0;
  }

  video {
    /* object-fit: none; */
  }

  .video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`;

const Car = () => {
  return (
    <>
      <CarStyles>
        <p>
          <b>Imagine a city with cars moving through it.</b> This is a busy place. Each car has a different driver,
          and they’re all carrying different items to deliver or transport across the city. There are many
          routes cross the city, some on the main roads, highways that cut through the city. Travelling
          along these is the quickest route through the city, but the cars must pay to use them. When the cars get
          off the highway, they must use side streets to get to their destination, and the cars can’t move
          as fast- but once they reach their destination, they can make their delivery!
        </p>
        <div className="video-container">
          <video
            src="./output_768.mp4"
            autoPlay={true}
            loop={true}
            muted={true}
          />
          <span>Free Stock Footage by <a href="http://www.videezy.com">Videezy.com</a> </span>
        </div>
        <p>
          The movement of mitochondria in the cell can be thought of in the same way. Each
          mitochondrion (car) has a different amount of mtDNA (its passenger), and carries a different
          amount of energy, metabolites and proteins (its cargo). The mitochondria can use actin
          filaments (highways) to move across the cell quickly, but must pay in ATP (the energy currency
          of the cell). These mitochondria move about the cell making deliveries, keeping the cell
          working- and delivering energy to where it’s needed the most!
        </p>
        <p>
          We study this movement in the city as whole. We know the population of mitochondria
          in plant cells move around in this way, but we’re looking to go deeper- why would mitochondria
          moving in this way help the cell? We think it’s really important to look at the city as whole- not
          just look at one individual- one car- but all of it as one living, communicating, interacting
          system.
        </p>
      </CarStyles>
    </>
  );
}

export default Car;